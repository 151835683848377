import { useState } from "react";
import {
  useAuthenticatorData,
  useUserAuthentication,
} from "../AuthenticatorProvider";
import SignInModal from "../components/SignInModal";
import { linkBarData } from "../constants";
import { useSpinnerData } from "../SpinnerProvider";
import SocialLink from "./SocialLink";

const TopHeader = () => {
  const [toggle, setToggle] = useState(false);
  const { user } = useAuthenticatorData();
  const { logout } = useUserAuthentication();
  const { show, hide } = useSpinnerData();

  return (
    <div className="top-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6" />
          <div
            className="col-lg-6 col-md-6"
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <ul className="top-social">
              {linkBarData.map((lbd, index) => (
                <li key={index}>
                  <SocialLink {...lbd} />
                </li>
              ))}
            </ul>
            <ul className="nav-menu nav-menu-social align-to-right">
              {user ? (
                <>
                  <li>
                    <span>{user}</span>
                  </li>
                  <li>
                    <span
                      onClick={() => {
                        show();
                        logout().then(() => {
                          hide();
                          sessionStorage.removeItem("ACCESS_TOKEN");
                          sessionStorage.removeItem("user");
                          window.location.reload();
                        });
                      }}
                      className="alio_green"
                      style={{
                        padding: 0,
                        marginLeft: 10,
                        marginRight: 10,
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      <span className="dn-lg">Log out</span>
                      <i className="fas fa-sign-out-alt ml-1"></i>
                    </span>
                  </li>
                </>
              ) : (
                <li>
                  <span
                    onClick={() => setToggle(true)}
                    className="alio_green"
                    style={{
                      padding: 0,
                      marginRight: 10,
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fas fa-sign-in-alt mr-1"></i>
                    <span className="dn-lg">Sign In</span>
                  </span>
                  <SignInModal show={toggle} onClose={() => setToggle(false)} />
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
