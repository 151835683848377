import React from "react";
import { linkBarData } from "../constants";
import ExternalLink from "../components/ExternalLink";

const FollowUsOn = () => (
  <ul className="footer-menu">
    {linkBarData.map((l, index) => (
      <li key={index}>
        <ExternalLink href={l.link} label={l.name} />
      </li>
    ))}
  </ul>
);

export default FollowUsOn;
