import { Link as ReactRouterLink } from "react-router-dom";

const Link = ({ href, label, children, ...props }) => {
  if (!href) {
    return (
      <a href={href} {...props}>
        {label || children}
      </a>
    );
  }
  return (
    <ReactRouterLink to={href} {...props}>
      {label || children}
    </ReactRouterLink>
  );
};

export default Link;
