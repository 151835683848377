import React from "react";
import {
  COMPANY_EMAIL,
  COMPANY_NAME,
  FORMATTED_COMPANY_CONTACT,
} from "../constants";
import AppLogo from "../Header/AppLogo";
import FollowUsOn from "./FollowUsOn";
import FooterBottom from "./FooterBottom";
import Layout from "./Layout";

const Footer = () => (
  <footer className="dark-footer skin-dark-footer style-2">
    <div className="footer-middle">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-5">
            <div className="footer_widget">
              <AppLogo />
              <h4 className="extream mb-3">{COMPANY_NAME}</h4>
              <p>{COMPANY_EMAIL}</p>
              <p>{FORMATTED_COMPANY_CONTACT}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-7 ml-auto">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="footer_widget">
                  <h4 className="widget_title">{"Layouts"}</h4>
                  <Layout />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="footer_widget">
                  <h4 className="widget_title">{"Follow Us On,"}</h4>
                  <FollowUsOn />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterBottom />
  </footer>
);

export default Footer;
