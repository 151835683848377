import { useState } from "react";
import { useMemo } from "react";
import { createContext, useContext } from "react";

const FilterContext = createContext({ searchData: {} });

export const useFilterData = () => useContext(FilterContext);

const useFetchFilter = () => {
  const [selectedFilter, setSelectedFilter] = useState({
    SquareFtRanges: {
      from: 0,
      to: 10000,
    },
    SortBy: "1",
  });

  return useMemo(
    () => ({
      selectedFilter,
      setSelectedFilter: (data) => setSelectedFilter(data),
    }),
    [selectedFilter, setSelectedFilter]
  );
};

const FilterProvider = ({ children }) => {
  const data = useFetchFilter();
  return (
    <FilterContext.Provider value={data}>{children}</FilterContext.Provider>
  );
};

export default FilterProvider;
