import {
  identity, pick,
  pickBy
} from "lodash";
import { useNavigate } from "react-router-dom";
import { serializeQueryObject } from "../../utils";
import { useFilterData } from "../../FilterProvider";
import { useSpinnerData } from "../../SpinnerProvider";
import { BasicFieldsWithId, ItemDetailsFields } from "./index";
import { saveProduct } from "../../api";

export const useFormSubmit = (onSuccess) => {
  const { show, hide } = useSpinnerData();
  const { selectedFilter } = useFilterData();
  const navigate = useNavigate();

  const onSubmit = (form) => {
    show();
    saveProduct(form, BasicFieldsWithId, ItemDetailsFields, pick)
      .then(async (response) => {
        const data = await response.json();
        hide();
        if (response.status === 200) {
          if (selectedFilter.SquareFtRanges && selectedFilter.SortBy) {
            navigate(
              `/products?${serializeQueryObject(
                pickBy(
                  {
                    minArea: selectedFilter.SquareFtRanges.from,
                    maxArea: selectedFilter.SquareFtRanges.to,
                    sortById: selectedFilter.SortBy,
                    priceRanges: selectedFilter?.PriceRangesId,
                    itemTypeId: selectedFilter?.value,
                  },
                  identity
                )
              )}`
            );
          } else {
            navigate("/");
          }
        } else {
          onSuccess((f) => ({
            ...f,
            submitError: data.error_description || data.Message,
          }));
        }
      })
      .catch((error) => {
        hide();
        onSuccess((f) => ({
          ...f,
          submitError: error.message,
        }));
      });
  };

  return { onSubmit };
};
