import { useEffect, useRef, useState } from "react";
import Link from "../components/Link";

export function useOutsideAlerter(ref, onOutsideClick) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick]);
}

const NavLinkItem = ({ link, name, subItems, onClick }) => {
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setShow(false));

  return (
    <li ref={wrapperRef}>
      <Link
        href={link}
        onClick={(event) => {
          if (!!subItems) {
            event.preventDefault();
            setShow(!show);
            return;
          }
          onClick?.();
        }}
      >
        {name}
        {!!subItems && (
          <span className="submenu-indicator">
            <span className="submenu-indicator-chevron"></span>
          </span>
        )}
      </Link>
      {!!subItems && (
        <ul
          className="nav-dropdown nav-submenu"
          style={show ? { display: "block" } : {}}
        >
          {subItems.map((si, index) => (
            <li key={index}>
              <Link
                href={si.link}
                onClick={() => {
                  setShow(false);
                  onClick?.();
                }}
                label={si.name}
              />
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default NavLinkItem;
