import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Product } from "./Product";
import { useLocation, useNavigate } from "react-router-dom";
import {
  camelCase,
  identity,
  isEmpty,
  map,
  omit,
  pickBy,
  reduce,
  size,
} from "lodash";
import Select from "react-select";
import IonRangeSlider from "react-ion-slider";
import { Collapse } from "react-collapse";
import { useEnumData } from "../../EnumProvider";
import { useRef } from "react";
import { useSpinnerData } from "../../SpinnerProvider";
import { useFilterData } from "../../FilterProvider";
import { serializeQueryObject } from "../../utils";
import { search } from "../../api";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const useFetchProducts = (minArea, maxArea, sortById, itemTypeId, setProducts) => {
  const navigate = useNavigate();
  const fetchCountRef = useRef();
  const { show, hide } = useSpinnerData();

  useEffect(() => {
    if (!fetchCountRef.current) {
      show();
      fetchCountRef.current = true;
      search({
        minArea: minArea, 
        maxArea: maxArea, 
        sortById: sortById,
        itemTypeId: itemTypeId
      })
        .then(async (data) => {
          hide();
          fetchCountRef.current = false;
          if (data.status === 200) {
            setProducts(await data.json());
          } else {
            setProducts([]);
          }
        })
        .catch(function (error) {
          hide();
          fetchCountRef.current = false;
          console.log(error);
          // navigate("/");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, minArea, maxArea, sortById, setProducts]);
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => {
    const query = new URLSearchParams(search);
    return {
      minArea: query.get("minArea") || 0,
      maxArea: query.get("maxArea") || 10000,
      sortById: query.get("sortById") || 1,
      priceRanges: query.get("priceRanges"),
      itemTypeId: query.get("itemTypeId"),
    };
  }, [search]);
}

const Products = () => {
  const { minArea, maxArea, sortById, priceRanges, itemTypeId } = useQuery();
  const [products, setProducts] = useState([]);
  const { selectedFilter, setSelectedFilter } = useFilterData();
  useFetchProducts(minArea, maxArea, sortById, itemTypeId, setProducts);
  const [toggle, setToggle] = useState(true);
  const [width] = useWindowSize();
  const { show, hide } = useSpinnerData();
  const { searchData } = useEnumData();

  const defaultFilters = {
    SquareFtRanges: {
      from: minArea,
      to: maxArea,
    },
    SortBy: sortById,
    PriceRanges: priceRanges,
    Types: itemTypeId,
  };

  const handleReset = () => setSelectedFilter(defaultFilters);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (filters = selectedFilter) => {
    console.log(filters);
    show();
    window.history.replaceState(
      null,
      document.getElementsByTagName("title")[0].innerHTML,
      `/products?${serializeQueryObject(
        pickBy(
          {
            minArea: filters.SquareFtRanges.from,
            maxArea: filters.SquareFtRanges.to,
            sortById: filters.SortBy,
            priceRanges: filters?.PriceRanges,
            itemTypeId: filters?.Types,
          },
          identity
        )
      )}`
    );

    search({
      minArea: filters.SquareFtRanges.from,
      maxArea: filters.SquareFtRanges.to,
      sortById: filters.SortBy,
      itemTypeId: filters.Types,
      ...reduce(
        omit(filters, ["SquareFtRanges", "SortBy", "Types"]),
        (acc, value, key) => ({
          ...acc,
          [camelCase(key)]: value,
        }),
        {}
      ),
    })
      .then(async (data) => {
        hide();
        if (data.status === 200) {
          setProducts(await data.json());
        } else {
          setProducts([]);
        }
      })
      .catch(function (error) {
        hide();
        console.log(error);
        // navigate("/");
      });
  };

  if (isEmpty(searchData)) {
    return <div style={{ minHeight: "100vh" }}></div>;
  }

  return (
    <section className="gray pt-4">
      <div className="container">
        <div className="row m-0">
          <div className="short_wraping">
            <div className="row align-items-center">
              <div className="col-lg-9 col-md-12 col-sm-12 order-lg-2 order-md-3 elco_bor col-sm-12"></div>

              <div className="col-lg-3 col-md-6 col-sm-12 order-lg-3 order-md-2 col-sm-6">
                <div className="shorting-right">
                  <label style={{ marginRight: 10 }}>{"Sort By: "}</label>
                  <Select
                    styles={{
                      menuPortal: (baseStyles, state) => ({
                        ...baseStyles,
                        zIndex: 99,
                      }),
                    }}
                    value={
                      selectedFilter["SortBy"]
                        ? {
                            value: selectedFilter["SortBy"],
                            label:
                              searchData["SortBy"][selectedFilter["SortBy"]],
                          }
                        : null
                    }
                    onChange={(data) => {
                      setSelectedFilter({
                        ...selectedFilter,
                        SortBy: data.value,
                      });
                      handleSearch();
                    }}
                    menuPosition="fixed"
                    placeholder={"SortBy"}
                    options={map(searchData["SortBy"], (label, value) => ({
                      value,
                      label,
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="page-sidebar p-0">
              <a className="filter_links" onClick={() => setToggle(!toggle)}>
                Open Advance Filter<i className="fa fa-sliders-h ml-2"></i>
              </a>
              <Collapse isOpened={width <= 991 ? toggle : true}>
                <div className="sidebar-widgets p-4">
                  {map(
                    omit(searchData, ["SquareFtRanges", "Features", "SortBy"]),
                    (v, k) => (
                      <div className="form-group" key={k}>
                        <div className="simple-input">
                          {selectedFilter[k] && <label>{k}</label>}
                          <Select
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            value={
                              selectedFilter[k]
                                ? {
                                    value: selectedFilter[k],
                                    label: v[selectedFilter[k]],
                                  }
                                : null
                            }
                            onChange={(data) =>
                              setSelectedFilter({
                                ...selectedFilter,
                                [k]: data.value,
                              })
                            }
                            menuPosition="fixed"
                            placeholder={k}
                            options={map(v, (label, value) => ({
                              value,
                              label,
                            }))}
                          />
                        </div>
                      </div>
                    )
                  )}
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
                      <h6>Choose SquareFt Ranges</h6>
                      <div className="rg-slider">
                        <IonRangeSlider
                          grid
                          type="double"
                          min={+minArea || 0}
                          max={+maxArea || 10000}
                          from={+selectedFilter["SquareFtRanges"].from}
                          to={+selectedFilter["SquareFtRanges"].to}
                          step={1}
                          onChange={({ from, to }) =>
                            setSelectedFilter({
                              ...selectedFilter,
                              SquareFtRanges: { from, to },
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 pt-4">
                      <h6>Advance Features</h6>
                      <ul className="row p-0 m-0">
                        {map(searchData["Features"], (value) => (
                          <li className="col-lg-6 col-md-6 p-0" key={value}>
                            <input
                              className="checkbox-custom"
                              name={`checkbox_${value}`}
                              type="checkbox"
                              checked={selectedFilter[value] || false}
                              onChange={() => {}}
                            />
                            <label
                              onClick={() => {
                                setSelectedFilter({
                                  ...selectedFilter,
                                  [value]: !selectedFilter[value],
                                });
                              }}
                              className="checkbox-custom-label"
                            >
                              {value}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12 col-sm-12 pt-4"
                      style={{
                        justifyContent: "space-evenly",
                        display: "flex",
                      }}
                    >
                      <button
                        className="btn theme-bg rounded"
                        onClick={() => handleSearch()}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>

          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="row justify-content-center">
              {size(products) ? (
                map(products, (x, i) => (
                  <div className="col-lg-6 col-md-6 col-sm-12" key={i}>
                    <Product
                      images={null}
                      {...x}
                      onSearch={handleSearch}
                    />
                  </div>
                ))
              ) : (
                <span>{"No results found."}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
