import ExternalLink from "../components/ExternalLink";

const SocialLink = ({ link, icon, image }) => (
  <ExternalLink
    href={link}
    label={
      icon ? (
        <i className={icon}></i>
      ) : (
        <img src={image} alt="icon" width="12px" />
      )
    }
  />
);

export default SocialLink;
