export const CustomCheckbox = ({ form, className, fieldKey, label, onChange }) => (
  <div
    className={`form-group ${className} ${form.errors[fieldKey] ? "is-invalid" : ""}`}
  >
    <input
      id={fieldKey}
      className="checkbox-custom"
      name={fieldKey}
      type="checkbox"
      checked={form[fieldKey]}
      onChange={(e) => onChange(fieldKey, e.target.checked)} />
    <label htmlFor={fieldKey} className="checkbox-custom-label">
      {label}
    </label>
  </div>
);
