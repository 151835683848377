import Slider from "react-slick";
import React from "react";
import Testimonial from "../../components/Testimonial";
import ClearFix from "../../components/ClearFix";
import Link from "../../components/Link";

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Testimonials = ({ hideMoreTestimonialsBtn, testimonialData }) => (
  <section
    className="gray image-cover"
    style={{
      background: "#122947 url(/assets/img/pattern.png) no-repeat",
    }}
  >
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-7 col-md-8">
          <div className="sec-heading center light">
            <h2>{"Testimonials"}</h2>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-8" style={{ marginBottom: 50 }}>
          <Slider {...settings} className="modern-testimonial">
            {testimonialData.map((td, index) => (
              <Testimonial {...td} key={index} />
            ))}
          </Slider>
        </div>
      </div>
      <ClearFix />
      {!hideMoreTestimonialsBtn && (
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <Link href="/about-us" className="btn btn-theme-light-2 rounded">
              {"More Testimonials"}
            </Link>
          </div>
        </div>
      )}
    </div>
  </section>
);

export default Testimonials;
