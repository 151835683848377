const PortfolioHeading = () => (
  <div className="row justify-content-center">
    <div className="col-lg-7 col-md-8">
      <div className="sec-heading center">
        <h2>{"Our Portfolio"}</h2>
        <p>
          {
            "Check out our recent work below. If you are wanting a specific house plan contact us now!"
          }
        </p>
      </div>
    </div>
  </div>
);

export default PortfolioHeading;
