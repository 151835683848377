import { Link } from "react-router-dom";
import { useSpinnerData } from "../../SpinnerProvider";
import { Details } from "./Details";
import { Features } from "./Features";
import { Images } from "./Images";
import { Price } from "./Price";
import { deleteProduct } from "../../api";

export const Product = ({ onSearch, ...product }) => {
  const { show, hide } = useSpinnerData();
  const handleDelete = (e) => {
    e.preventDefault();
    show();
    deleteProduct(product.ItemId)
      .then(async (response) => {
        const data = await response.json();
        console.log(data);
        if (response.status === 200) {
          onSearch();
        }
        hide();
      })
      .catch((error) => {
        hide();
      });
  };

  return (
    <div className="property-listing property-2">
      <Link to={`/product/${product.ItemId}`} className="prt-link-detail">
        <>
          <Images 
            images={product.images}
            itemId={product.ItemId}
          />
          <Details
            name={product.Name || "N/A"}
            id={product.ItemId}
            itemType={product.ItemType}
            itemCategory={product.ItemCategory}
            onDelete={handleDelete}
          />
          <Features
            bed={product.Bedrooms || 0}
            size={product.SquareFt || 0.0}
            bath={product.Bathrooms || 0}
          />
        </>
      </Link>
      <Price price={product.Price || 0.0} id={product.ItemId} />
    </div>
  );
};
