import yelpForBusinessLogo from "./images/Yelp for business.png";
import linkLogo from "./images/link.png";

export const linkBarData = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/Almodovar-Drafting-and-Design-LLC-101412511935298",
    icon: "lni-facebook",
  },
  {
    name: "Yelp For Business",
    link: "https://www.yelp.com/biz/almodovar-drafting-and-design-panama-city-beach?utm_campaign=www_business_share_popup&amp;utm_medium=copy_link&amp;utm_source=(direct)",
    image: yelpForBusinessLogo,
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/almodovardraftinganddesign/",
    icon: "lni-instagram",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/bryan-almodovar-596451167/",
    icon: "lni-linkedin",
  },
  {
    name: "Home Advisor",
    link: "http://www.homeadvisor.com/rated.AlmodovarDrafting.77957326.html",
    image: linkLogo,
  },
];

export const services = [
  {
    name: "Residential Plans",
    link: "residential-plans",
    description:
      `<div>We have plans to select. We can provide...</div>
      <div>What is included into the plans:</div>
      <ol>
        <li>Conver sheet - providing all information regarding the house plans, i.e. sqft, #bedroom, #bathroom, etc.</li>
        <li>Site Plans - Locating the house within the parcel showing the required setbacks and information.</li>
        <li>Floors plan - showing all areas of the house, along with dimensions and Roof plan - showing type of roof and pitch</li>
        <li>Elevations - all exterior sides of the house and cut sections showing the interior of the house providing information about the structural components.</li>
        <li>Door and Window schedule, Trim details, other detials.</li>
        <li>MEP - Mechanical, Electrical and plumbing. Diagrams providing necessary information for permitting.</li>
        <li>Structural plans: Foundation, Wall sections, Framing plans, Truss plans, Structural details</li>
        <li>Landscaping plans: Irrigation plans, Landscape, Hardscape, etc.</li>
      </ol>
      <div>Note:</div>
      <ul>
        <li>Site plans and Landscape will be at an additional cost. Additional information will be required to generate these plans.</li>
        <li>Structural plans need to be certified by a P.E. on the state/county where the dwelling will be built.</li>
        <li>Changes to available plans will be an extra charge. Additional information will be required to generate these plans.</li>
      </ul>
      `,
    photos: [
      "1.png",
      "2.png",
      "3.jpg",
      "4.png",
      "5.jpg",
      "6.png",
      "7.jpg",
      "8.jpg",
    ],
    itemTypeId: 1
  },
  {
    name: "Commercial Projects",
    link: "commercial-plans",
    description:
      "You may have great business ideas but you never take any action on them. Almodovar Drafting and Design is here to make your ideas come to life. We'll elaborate the best concepts for your business ideas. I’m passionate about creating useful and comfortable spaces that also help make the world a more beautiful place. All of my projects marry creative inspiration with each client’s specific needs and expectations. Get in touch with us to get more information!",
    photos: ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg"],
    tabs: [
      {
        title: "Concept Plans",
      },
      {
        title: "Renderings",
      },
    ],
    itemTypeId: 2
  },
  {
    name: "Fabrication Drawings and 3D Modeling",
    link: "shop-drawings",
    description:
      "Almodovar Drafting and Design  is known for the quality of each of their projects. They are creative, receptive and not afraid to push the boundaries of the possible. We make all kinds of shop drawings like fabrication shops, shipyards , from concepts to assembly plan and 3D Modeling.  We also work with the best contractors and engineers to provide the best service. Get in contact with us for more information!",
    photos: [
      "1.png",
      "2.jpg",
      "3.png",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
    ],
    itemTypeId: 3
  }
];

export const navigationData = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "About Us",
    link: "/about-us",
  },
  {
    name: "Services",
    link: "",
    subItems: [
      { name: "Residential Plans", link: "residential-plans" },
      { name: "Commercial Projects", link: "commercial-plans" },
      { name: "Fabrication Drawings and 3D Modeling", link: "shop-drawings" },
    ],
  },
  {
    name: "Portfolio",
    link: "/portfolio",
  },
  {
    name: "Contact Us",
    link: "/contact-us",
  },
];

export const COMPANY_FULL_NAME = "Almodovar Drafting and Design LLC";
export const COMPANY_NAME = "Almodovar Drafting & Design, LLC";
export const COMPANY_FULL_NAME_WITHOUT_LLC = "Almodovar Drafting and Design";
export const COMPANY_NAME_WITHOUT_LLC = "Almodovar Drafting & Design";
export const COMPANY_EMAIL = "Bryan.A@AlmodovarDD.com";
export const FORMATTED_COMPANY_CONTACT = "(850) 276-8202";
export const COMPANY_CONTACT = "8502768202";
export const COPY_WRITE_YEAR = new Date().getUTCFullYear();
