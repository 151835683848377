import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { createContext, useContext } from "react";
import { fetchEnums } from "./api";

const EnumContext = createContext({ searchData: {} });

export const useEnumData = () => useContext(EnumContext);

const useFetchEnum = () => {
  const [searchData, setSearchData] = useState({});
  const fetchCountRef = useRef();

  useEffect(() => {
    if (!fetchCountRef.current) {
      fetchCountRef.current = true;
      fetchEnums().then(async (data) => {
          fetchCountRef.current = false;
          if (data.status === 200) {
            setSearchData(await data.json());
          }
        })
        .catch(function (error) {
          fetchCountRef.current = false;
          console.log(error);
        });
    }
  }, []);

  return useMemo(() => ({ searchData }), [searchData]);
};

const EnumProvider = ({ children }) => {
  const data = useFetchEnum();
  return <EnumContext.Provider value={data}>{children}</EnumContext.Provider>;
};

export default EnumProvider;