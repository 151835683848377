import React from "react";
import ReactDOM from "react-dom/client";
import "react-tabs/style/react-tabs.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "core-js/es/promise";
import "core-js/es/set";
import "core-js/es/map";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Modal from "react-modal";
import AuthenticatorProvider from "./AuthenticatorProvider";
import EnumProvider from "./EnumProvider";
import SpinnerProvider from "./SpinnerProvider";
import FilterProvider from "./FilterProvider";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");
// sessionStorage.setItem("showHiringModal", true);

const root = ReactDOM.createRoot(document.getElementById("root"));
// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

root.render(
  <BrowserRouter>
    <SpinnerProvider>
      <AuthenticatorProvider>
        <FilterProvider>
          <EnumProvider>
            <AlertProvider template={AlertTemplate} {...options}>
              <App />
            </AlertProvider>
          </EnumProvider>
        </FilterProvider>
      </AuthenticatorProvider>
    </SpinnerProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
