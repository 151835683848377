import React from "react";
import { COMPANY_NAME, COPY_WRITE_YEAR } from "../constants";

const FooterBottom = () => (
  <div className="footer-bottom">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-12 col-md-12 text-center">
          <p className="mb-0">{`© ${COPY_WRITE_YEAR} ${COMPANY_NAME}`}</p>
        </div>
      </div>
    </div>
  </div>
);

export default FooterBottom;
