import { LightBoxGallery, GalleryItem } from "@sekmet/react-magnific-popup";
import magnificPopupConfig from "../../magnific-popup-config";

const PortfolioItem = ({ mainImg, portfolioData }) => (
  <div>
    <section className="gray min pt-0">
      <div
        className="image-cover hero_banner"
        style={{
          background: `url(${mainImg}) no-repeat`,
        }}
        data-overlay="5"
      >
        <div className="container"></div>
      </div>
    </section>
    <LightBoxGallery className="popup-gallery" config={magnificPopupConfig}>
      <div className="container">
        <div className="row justify-content-center">
          {portfolioData.map((x, index) => (
            <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
              <GalleryItem href={x} className="img-wrap">
                <div
                  className="img-wrap-background"
                  style={{ backgroundImage: `url(${x})` }}
                ></div>
              </GalleryItem>
            </div>
          ))}
        </div>
      </div>
    </LightBoxGallery>
  </div>
);

export default PortfolioItem;
