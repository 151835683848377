import { isEmpty, map, pick, reduce, size } from "lodash";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useParams } from "react-router-dom";
import { useEnumData } from "../../EnumProvider";
import { CustomCheckbox } from "./CustomCheckbox";
import { CustomSelect } from "./CustomSelect";
import { CustomText } from "./CustomText";
import { CustomTextArea } from "./CustomTextArea";
import { FilePreview } from "./FilePreview";
import { useFetchProduct } from "./useFetchProduct";
import { useFileDelete } from "./useFileDelete";
import { useFileUpload } from "./useFileUpload";
import { useFormSubmit } from "./useFormSubmit";
import { validateInputs } from "./validateInputs";

export const ItemDetailsFields = [
  "Description",
  "SquareFt",
  "Bedrooms",
  "Bathrooms",
  "LivingRooms",
  "Kitchens",
  "GarageSpots",
  "ParkingSpots",
  "Fireplaces",
];

export const BasicFields = [
  "Name",
  "Price",
  "ItemCategoryId",
  "ItemTypeId",
  "IsActive",
];

const additionalFeatures = [
  {
    fieldKey: "Bedrooms",
    label: "Bedrooms",
  },
  {
    fieldKey: "Bathrooms",
    label: "Bathrooms",
  },
  {
    fieldKey: "LivingRooms",
    label: "Living Rooms",
  },
  {
    fieldKey: "Kitchens",
    label: "Kitchens",
  },
  {
    fieldKey: "GarageSpots",
    label: "Garage Spots",
  },
  {
    fieldKey: "ParkingSpots",
    label: "Parking Spots",
  },
  {
    fieldKey: "Fireplaces",
    label: "Fireplaces",
  },
];

const additionalFeaturesBoolean = [
  
];

const booleanInputOption = [
  {
    value: true,
    label: "True",
  },
  {
    value: false,
    label: "False",
  },
];

const ameneties = [
  { fieldKey: "Pool", label: "Pool" },
  { fieldKey: "DetachedGarage", label: "Detached Garage" },
  { fieldKey: "Pantry", label: "Pantry" },
  { fieldKey: "Laundry", label: "Laundry" },
  { fieldKey: "MasterSuite", label: "Master Suite" },
  { fieldKey: "Theater", label: "Theater" },
  { fieldKey: "Elevator", label: "Elevator" },
  { fieldKey: "Office", label: "Office" },
  { fieldKey: "FamilyRoom", label: "Family Room" },
  { fieldKey: "Porches", label: "Porches" },
  { fieldKey: "Foyer", label: "Foyer" },
  { fieldKey: "MudRoom", label: "MudRoom" },
];

export const BasicFieldsWithId = ["ItemId", ...BasicFields];

const defaultFormState = {
  Name: "",
  Price: 0,
  ItemCategoryId: 1,
  ItemTypeId: 1,
  IsActive: true,
  Description: "",
  SquareFt: 0,
  Bedrooms: 0,
  Bathrooms: 0,
  LivingRooms: 0,
  Kitchens: 0,
  GarageSpots: 0,
  ParkingSpots: 0,
  Fireplaces: 0,
  errors: {},
};

export const formValidation = (form) =>
  reduce(
    pick(form, [...BasicFields, ...ItemDetailsFields]),
    (acc, value, key) => ({
      ...acc,
      ...validateInputs(key, value),
    }),
    {}
  );

const ProductAddEdit = () => {
  const { id } = useParams();
  const isEditing = !!id;
  const { searchData } = useEnumData();
  const [files, setFiles] = useState([]);
  const [form, setForm] = useState(
    isEditing
      ? {
          ItemId: id,
          ...defaultFormState,
        }
      : defaultFormState
  );

  const { uploadFile } = useFileUpload(id, setFiles);
  const { deleteFile } = useFileDelete(id, setFiles);
  const { onSubmit } = useFormSubmit(setForm);
  const { fetchProduct } = useFetchProduct(setForm, setFiles);

  const optionMapper = (data) =>
    map(data, (label, value) => ({
      value,
      label,
    }));

  const enumOptions = reduce(
    searchData,
    (acc, value, key) => ({
      ...acc,
      [key]: optionMapper(value),
    }),
    {}
  );

  useEffect(() => {
    if (isEditing) {
      fetchProduct(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (key, value) => {
    setForm({
      ...form,
      [key]: value
    })
    //setForm((f) => {
    //  const newForm = {
    //    ...f,
    //    [key]: value,
    //  };
    //  return {
    //    ...newForm,
    //    errors: validateInputs(key, value),
    //  };
    //});
  };

  const handleSubmit = () => {
    const onSubmitValidation = formValidation(form);
    if (!isEmpty(onSubmitValidation)) {
      setForm((f) => ({
        ...f,
        errors: onSubmitValidation,
      }));
      return;
    }

    onSubmit(form);
  };

  const hasValidInputs = isEmpty(form.errors);

  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="submit-page px-4">
            <div className="frm_submit_block">
              <h3>Basic Information</h3>
              <div className="frm_submit_wrap">
                <div className="form-row">
                  <CustomText
                    className="col-md-12"
                    form={form}
                    fieldKey="Name"
                    label="Name"
                    onChange={handleInputChange}
                  />
                  <CustomSelect
                    className="col-md-6"
                    form={form}
                    fieldKey="ItemTypeId"
                    label="Item Type"
                    onChange={handleInputChange}
                    options={enumOptions["Types"]}
                  />
                  <CustomSelect
                    className="col-md-6"
                    form={form}
                    fieldKey="ItemCategoryId"
                    label="Item Category"
                    onChange={handleInputChange}
                    options={enumOptions["Categories"]}
                  />
                  <CustomText
                    className="col-md-6"
                    form={form}
                    fieldKey="Price"
                    label="Price"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            {isEditing && (
              <div className="frm_submit_block">
                <h3>Gallery</h3>
                <div className="frm_submit_wrap">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>
                        Upload Gallery<span className="required">*</span>
                      </label>
                      <div
                        action="https://LoopCode.net/upload-target"
                        className="dropzone dz-clickable primary-dropzone"
                      >
                        <Dropzone
                          multiple
                          accept={{
                            "image/*": [".png", ".gif", ".jpeg", ".jpg"],
                          }}
                          onDrop={uploadFile}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="dz-default dz-message">
                                  <i className="ti-gallery"></i>
                                  <span> Drag & Drop Photos</span>
                                </div>
                              </div>
                              <aside>
                                {size(files) !== 0 &&
                                  map(files, (file) => (
                                    <FilePreview
                                      file={file.Url}
                                      onDelete={() => deleteFile(file.Name)}
                                    />
                                  ))}
                              </aside>
                            </>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="frm_submit_block">
              <h3>Detailed Information</h3>
              <div className="frm_submit_wrap">
                <div className="form-row">
                  <CustomTextArea
                    className="col-md-12"
                    form={form}
                    fieldKey="Description"
                    label="Description"
                    onChange={handleInputChange}
                  />
                  <CustomText
                    className="col-md-6"
                    form={form}
                    fieldKey="SquareFt"
                    label="SquareFt Area"
                    onChange={handleInputChange}
                  />
                  <div className="form-group col-md-6"></div>
                  {map(additionalFeatures, ({ fieldKey, label }) => (
                    <CustomText
                      key={fieldKey}
                      className="col-md-2"
                      form={form}
                      fieldKey={fieldKey}
                      label={label}
                      onChange={handleInputChange}
                      //options={enumOptions[fieldKey]}
                    />
                  ))}
                  {map(additionalFeaturesBoolean, ({ fieldKey, label }) => (
                    <CustomSelect
                      key={fieldKey}
                      className="col-md-4"
                      form={form}
                      fieldKey={fieldKey}
                      label={label}
                      onChange={handleInputChange}
                      options={booleanInputOption}
                      isBoolean
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className="frm_submit_block">
              <h3>Ameneties</h3>
              <div className="frm_submit_wrap">
                <div className="form-row">
                  {map(ameneties, ({ fieldKey, label }) => (
                    <CustomCheckbox
                      key={fieldKey}
                      className="col-md-4"
                      form={form}
                      fieldKey={fieldKey}
                      label={label}
                      onChange={handleInputChange}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div
              className={`form-group ${form.submitError ? "is-invalid" : ""}`}
            >
              <div className="col-lg-12 col-md-12">
                <button
                  className="btn btn-theme"
                  disabled={!hasValidInputs}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <div className="invalid-feedback">{form.submitError}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAddEdit;
