export const FilePreview = ({ file, onDelete }) => (
  <div className="dz-preview dz-processing dz-image-preview dz-error dz-complete">
    <div className="dz-image">
      <img data-dz-thumbnail="" alt={file} src={file} className="img-fluid mx-auto" />
    </div>
    <div className="dz-details">
      <div className="dz-filename">
        <span data-dz-name="">{file}</span>
      </div>
    </div>
    <div
      className="dz-success-mark"
      style={{
        opacity: 1,
        pointerEvents: "all",
      }}
    >
      <img
        onClick={onDelete}
        style={{
          cursor: "pointer",
        }}
        src="/assets/images/cross.svg"
        alt="cross" />
    </div>
  </div>
);
