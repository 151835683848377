import { map,slice } from "lodash";
import { useEffect, useState } from "react";
import { LightBoxGallery, GalleryItem } from "@sekmet/react-magnific-popup";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import magnificPopupConfig from "../../magnific-popup-config";
import { fetchProductById } from "../../api";
import { MainImage } from "../../components/MainImage";

const settings = {
  dots: true,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ameneties = [
  { fieldKey: "Pool", label: "Pool" },
  { fieldKey: "DetachedGarage", label: "Detached Garage" },
  { fieldKey: "Pantry", label: "Pantry" },
  { fieldKey: "Laundry", label: "Laundry" },
  { fieldKey: "MasterSuite", label: "Master Suite" },
  { fieldKey: "Theater", label: "Theater" },
  { fieldKey: "Elevator", label: "Elevator" },
  { fieldKey: "Office", label: "Office" },
  { fieldKey: "FamilyRoom", label: "Family Room" },
  { fieldKey: "Porches", label: "Porches" },
  { fieldKey: "Foyer", label: "Foyer" },
  { fieldKey: "MudRoom", label: "MudRoom" },
];

const ProductDetails = () => {
  const [product, setProduct] = useState({});
  const { id } = useParams();
  useEffect(() => {
    fetchProductById(id)
      .then(async (data) => {
        if (data.status === 200) {
          setProduct(await data.json());
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]);

  return (
    <>
      <section className="gallery_parts pt-2 pb-2 d-none d-sm-none d-md-none d-lg-none d-xl-block">
        <div className="container">
          
        </div>
      </section>

      <section className="pt-4">
        <div className="container">

          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div>
                <LightBoxGallery
                  className="popup-gallery"
                  config={magnificPopupConfig}
                >
                  <Slider
                    {...settings}
                    className="modern-testimonial"
                    style={{ marginBottom: "2rem" }}
                  >
                    {/* uncomment this if you want to display all images. */}
                     {/* {map(product.ImageUrls, (iu, index) => ( */}
                    {map(slice(product.ImageUrls, 0, 4), (iu, index) => (
                      <div className="featured_slick_padd" key={index}>
                        <GalleryItem href={iu.Url} className="mfp-gallery">
                          <img src={iu.Url} className="img-fluid mx-auto" alt={iu.Name} />
                        </GalleryItem>
                      </div>
                    ))}
                  </Slider>
                </LightBoxGallery>
              </div>

              <div className="property_info_detail_wrap mb-4">
                <div className="property_info_detail_wrap_first">
                  <div className="pr-price-into">
                    <ul className="prs_lists">
                      <li>
                        <span className="sqrt">{product.ItemType}</span>
                      </li>
                      <li>
                        <span className="bath">{product.ItemCategory}</span>
                      </li>
                    </ul>
                    <h2>{product.Name}</h2>
                    <span>{product.ItemCategory}</span>
                  </div>
                </div>
              </div>

              <div className="property_block_wrap">
                <div className="property_block_wrap_header">
                  <h4 className="property_block_title">About Product</h4>
                </div>

                <div className="block-body">
                  <p>{product.Description}</p>
                </div>
              </div>

              <div className="property_block_wrap">
                <div className="property_block_wrap_header">
                  <h4 className="property_block_title">Advance Features</h4>
                </div>

                <div className="block-body">
                  <ul className="row p-0 m-0">
                    <li className="col-lg-4 col-md-6 mb-2 p-0">
                      <i className="fa fa-bed mr-1"></i>
                      {product.Bedrooms || 0} Bedrooms
                    </li>
                    <li className="col-lg-4 col-md-6 mb-2 p-0">
                      <i className="fa fa-bath mr-1"></i>
                      {product.Bathrooms || 0} Bathrooms
                    </li>
                    <li className="col-lg-4 col-md-6 mb-2 p-0">
                      <i className="fa fa-expand-arrows-alt mr-1"></i>
                      {product.SquareFt || 0} sqft
                    </li>
                    <li className="col-lg-4 col-md-6 mb-2 p-0">
                      <i className="fa fa-house-damage mr-1"></i>
                      {product.LivingRooms || 0} Living Rooms
                    </li>
                    <li className="col-lg-4 col-md-6 mb-2 p-0">
                      <i className="fa fa-utensils mr-1"></i>
                      {product.Kitchens || 0} Kitchens
                    </li>
                    <li className="col-lg-4 col-md-6 mb-2 p-0">
                      <i className="fa fa-car mr-1"></i>
                      {product.ParkingSpots || 0} Parking
                    </li>
                    <li className="col-lg-4 col-md-6 mb-2 p-0">
                      <i className="fa fa-fire mr-1"></i>
                      {product.Fireplaces || 0} Fireplace
                    </li>
                    <li className="col-lg-4 col-md-6 mb-2 p-0">
                      <i className="fa fa-spa mr-1"></i>
                      {product.GarageSpots || 0} Garage Spots
                    </li>
                    <li className="col-lg-4 col-md-6 mb-2 p-0">
                      <i className="fa fa-layer-group mr-1"></i>
                      {product.ItemType}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="property_block_wrap">
                <div className="property_block_wrap_header">
                  <h4 className="property_block_title">Ameneties</h4>
                </div>

                <div className="block-body">
                  <ul className="avl-features third">
                    {map(ameneties, ({ fieldKey, label }) => (
                      <li
                        key={fieldKey}
                        className={product[fieldKey] ? "active" : ""}
                      >
                        {label}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="property_block_wrap">
                <div className="property_block_wrap_header">
                  <h4 className="property_block_title">Images</h4>
                </div>

                <div className="block-body">
                  <div>
                    <LightBoxGallery
                      className="popup-gallery"
                      config={magnificPopupConfig}
                    >
                      <Slider
                        {...settings}
                        className="modern-testimonial"
                        style={{ marginBottom: "2rem" }}
                      >
                        {map(product.ImageUrls, (iu, index) => (
                          <div className="featured_slick_padd" key={index}>
                            <GalleryItem href={iu.Url} className="mfp-gallery">
                              <img
                                src={iu.Url}
                                className="img-fluid mx-auto"
                                alt={iu.Name}
                              />
                            </GalleryItem>
                          </div>
                        ))}
                      </Slider>
                    </LightBoxGallery>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="property-sidebar">
                <div className="sidebar-widgets">
                  <h4>Similar Products</h4>
                  <div className="sidebar_featured_property">
                    {map(product.SimilarItems, (si) => (
                      <div className="sides_list_property">
                        <div className="sides_list_property_thumb">
                          <MainImage itemId={si.ItemId} />
                        </div>
                        <div className="sides_list_property_detail">
                          <h4>
                            <Link to={`/product/${si.ItemId}`}>
                              {si.ItemName}
                            </Link>
                          </h4>
                          <div className="lists_property_price">
                            <div
                              className="lists_property_types"
                              style={{ marginLeft: "-0.25rem" }}
                            >
                              <div className="property_types_vlix m-1 sqrt">
                                {si.ItemType}
                              </div>
                              <div className="property_types_vlix m-1 sale">
                                {si.ItemCategory}
                              </div>
                            </div>
                            <div className="lists_property_price_value">
                              <h4>${si.Price}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
