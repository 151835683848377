import Modal from "react-modal";
import Link from "./Link";
import AppLogo from "../Header/AppLogo";
import AppName from "../Header/AppName";
import { useState } from "react";

const customStyles = {
  content: {
    zIndex: "9999",
    position: "initial",
    maxHeight: "80vh",
  },
  overlay: {
    zIndex: "99999",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const positions = [
  {
    title: "Draftsman/CAD Operator and Document Control",
    description: [
      "Part Time (Full time if possible)",
      "Skills in AutoCAD, Adobe, and Microsoft applications (ex. Word and Excel)",
      "3 years experience in preparation of Civil and/or Architectural Plans",
      "Remote ",
      "Clearly understand the projects objective and the scope of work",
      "Good Communication Skills and be available for zoom meetings ",
    ],
  },
];

const ListItem = ({ list }) => (
  <div className="_prtis_list_body">
    <ul className="styled-list">
      {list.map((l, index) => (
        <li key={index} className="active">
          {l}
        </li>
      ))}
    </ul>
  </div>
);

const LaunchModal = () => {
  const showHiringModal = sessionStorage.getItem("showHiringModal") === "true";
  const [show, setShow] = useState(showHiringModal);
  const onClose = () => {
    setShow(false);
    sessionStorage.setItem("showHiringModal", false);
  };
  return (
    <Modal isOpen={show} style={customStyles} contentLabel="Example Modal">
      <div className="resp_log_wrap">
        <div className="resp_log_caption">
          <span
            className="mod-close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={onClose}
          >
            <i className="ti-close"></i>
          </span>
          <div className="edlio_152">
            <section className="min m-0 p-0">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-sm-12">
                    <div className="sec-heading center">
                      <Link className="nav-brand fixed-logo" href="/">
                        <div className="d-flex justify-content-start justify-content-md-center align-items-center">
                          <AppLogo />
                          <AppName />
                        </div>
                      </Link>
                      <h2>{"We are HIRING!"}</h2>
                      {positions.map((p, index) => (
                        <div key={index} className="_prtis_list mb-4">
                          <div className="_prtis_list_header min">
                            <h4 className="m-0">
                              <span className="theme-cl">{p.title}</span>
                            </h4>
                          </div>
                          <ListItem list={p.description} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div
              className="form-group"
              style={{ maxWidth: 200, margin: "auto" }}
            >
              <Link
                href="/contact-us"
                onClick={onClose}
                className="btn btn-md full-width pop-login"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LaunchModal;
