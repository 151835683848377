import { identity, map, pickBy, replace, split } from "lodash";
import { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useEnumData } from "../EnumProvider";
import { serializeQueryObject } from "../utils";

const SearchBox = () => {
  const [selectedArea, setSelectedArea] = useState();
  const [selectedType, setSelectedType] = useState();
  const [selectedPrice, setSelectedPrice] = useState();

  const { searchData } = useEnumData();

  const [min, max] = split(
    replace(selectedArea?.label, "+", "") || "0-10000",
    "-"
  );

  return (
    <div className="container" style={{ marginTop: 20 }}>
      <div
        className="full_search_box nexio_search lightanic_search hero_search-radius modern"
        style={{ margin: "10px auto", maxWidth: "80%" }}
      >
        <div className="search_hero_wrapping">
          <div className="row">
            <div className="col-lg-4 col-md-3 col-sm-12">
              <div className="form-group">
                <label>Square Ft Ranges</label>
                <Select
                  styles={{
                    menuPortal: (baseStyles, state) => ({
                      ...baseStyles,
                      zIndex: 99,
                    }),
                  }}
                  value={selectedArea}
                  onChange={setSelectedArea}
                  menuPosition="fixed"
                  menuPlacement="bottom"
                  options={map(
                    searchData["SquareFtRanges"],
                    (label, value) => ({
                      value,
                      label,
                    })
                  )}
                  id="location"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group">
                <label>Product Type</label>
                <Select
                  styles={{
                    menuPortal: (baseStyles, state) => ({
                      ...baseStyles,
                      zIndex: 99,
                    }),
                  }}
                  value={selectedType}
                  onChange={setSelectedType}
                  menuPosition="fixed"
                  menuPlacement="bottom"
                  options={map(searchData["Types"], (label, value) => ({
                    value,
                    label,
                  }))}
                  id="ptypes"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="form-group none">
                <label>Price Range</label>
                <Select
                  styles={{
                    menuPortal: (baseStyles, state) => ({
                      ...baseStyles,
                      zIndex: 99,
                    }),
                  }}
                  value={selectedPrice}
                  onChange={setSelectedPrice}
                  menuPosition="fixed"
                  menuPlacement="bottom"
                  options={map(searchData["PriceRanges"], (label, value) => ({
                    value,
                    label,
                  }))}
                  id="price"
                  className="form-control"
                />
              </div>
            </div>

            <div
              className="col-lg-1 col-md-2 col-sm-12 small-padd"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="form-group none">
                <Link
                  to={`/products?${serializeQueryObject(
                    pickBy(
                      {
                        minArea: min,
                        maxArea: max,
                        sortById: 1,
                        priceRanges: selectedPrice?.value,
                        itemTypeId: selectedType?.value,
                      },
                      identity
                    )
                  )}`}
                  className="btn search-btn"
                >
                  <i className="fa fa-search"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
