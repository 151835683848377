const AppLogo = () => (
  <img
    src="/assets/images/logo.jpg"
    height={36}
    className="logo"
    alt="rentvilla"
    style={{ marginRight: "1rem" }}
  />
);

export default AppLogo;
