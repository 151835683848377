import { useState } from "react";

export const MainImage = ({ itemId }) => {
  const baseUrl = `https://almodovardd.blob.core.windows.net/itemphotos/${itemId}/MainImage.jpg`;
  const fallbackUrl = "https://almodovardd.blob.core.windows.net/itemphotos/bg.jpg";
  const [imageLoaded , setImageLoaded ] = useState(true);
  function handleImageError (){
    setImageLoaded(false);
  }

  return (
    imageLoaded ? (
          <img src={baseUrl} alt="" onError={handleImageError} className="img-fluid" />
        ) : (
          <img src={fallbackUrl} alt="" className="img-fluid" />
        )
  );
};
