import React from "react";
import Testimonials from "./Testimonials";
import ServicesSection from "./ServicesSection";
import MainSection from "./MainSection";
import ClearFix from "../../components/ClearFix";
import LaunchModal from "../../components/LaunchModal";
import SearchBox from "../../components/SearchBox";

const servicesData = [
  {
    name: "Residential Plans",
    icon: "/assets/img/green/home.svg",
    hoverIcon: "/assets/img/white/home.svg",
    link: "residential-plans",
  },
  {
    name: "Commercial Projects",
    icon: "/assets/img/green/apartment.svg",
    hoverIcon: "/assets/img/white/apartment.svg",
    link: "commercial-plans",
  },
  {
    name: "Fabrication Drawings and 3D Modeling",
    icon: "/assets/img/green/architecture.svg",
    hoverIcon: "/assets/img/white/architecture.svg",
    link: "shop-drawings",
  }
];

const testimonialData = [
  {
    testimonial: (
      <>
        {
          "We are so excited to be building and appreciate everything you did to get us to this point."
        }
        <br />
        {
          "We highly recommend Almodovar Drafting and Design to design your dream home.  Bryan went above and beyond in guiding us through the design process. He offered helpful and cost saving suggestions. He thoroughly listened to our wish. We felt confident in his ability to provide us with a finished product that would suit our needs/wants. We are very pleased with our plans that were delivered timely. We cannot say enough about Bryan’s professionalism, knowledge, efficiency, politeness and attention to detail."
        }
      </>
    ),
    testimonialBy: "Randy and Sheila Merritt",
    testimonialTitle: "Home Owners",
  },
  {
    testimonial:
      "Bryan and his team have been very detailed in their approach throughout the design process and are very involved with assisting with plan approvals and assisting with project scoping.  We are very pleased with the results we have seen",
    testimonialBy: "Mike and Aimee Rose",
    testimonialTitle: "Home Owners",
  },
];

const Home = () => (
  <>
    <LaunchModal />
    <MainSection />
    <ClearFix />
    <SearchBox />
    <ClearFix />
    <ServicesSection servicesData={servicesData} />
    <ClearFix />
    <Testimonials testimonialData={testimonialData} />
    <ClearFix />
  </>
);

export default Home;
