const magnificPopupConfig = {
  delegate: "a",
  type: "image",
  tLoading: "Loading image #%curr%...",
  mainClass: "mfp-gallery",
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
  },
  image: {
    tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
    titleSrc: function (item) {
      return "";
    },
  },
};

export const getDynamicConfig = () => ({
  ...magnificPopupConfig,
  mainClass: `mfp-gallery_${Math.floor(Math.random() * 100)}`,
});

export default magnificPopupConfig;
