import { map } from "lodash";
import { MainImage } from "../../components/MainImage";

export const Images = ({ images, itemId }) => {
  if(!images) {
    return (
      <div className="listing-img-wrapper">
        <div className="list-img-slide">
          <div className="click">
            <MainImage itemId={itemId} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="listing-img-wrapper">
      <div className="list-img-slide">
        <div className="click">
            {map(images, (x, i) => (
                <img key={i} src={x} className="img-fluid mx-auto" alt="" />
            ))}
        </div>
      </div>
    </div>
  );
};