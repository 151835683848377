import "./App.css";
import React from "react";
import TopHeader from "./Header/TopHeader";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Pages/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";
import Services from "./Pages/Services";
import ClearFix from "./components/ClearFix";
import Portfolio, { portfolioData } from "./Pages/Portfolio";
import PortfolioItem from "./Pages/PortfolioItem";
import ContactUs from "./Pages/ContactUs";
import ScrollToTop from "./ScrollToTop";
import ScrollButton from "./ScrollButton";
import Products from "./Pages/Products";
import ProductDetails from "./Pages/ProductDetails";
import { useAuthenticatorData } from "./AuthenticatorProvider";
import ProductAddEdit from "./Pages/ProductAddEdit";
import { useSpinnerData } from "./SpinnerProvider";
import Spinner from "./components/Spinner";
import { services } from "./constants";

function RequireAuth({ children }) {
  const { user } = useAuthenticatorData();

  return user ? children : <Navigate to="/" replace />;
}

function App() {
  const { visible } = useSpinnerData();

  return (
    <div className="yellow-skin">
      {visible && <Spinner />}
      <div id="main-wrapper">
        <ScrollToTop />
        <TopHeader />
        <Header />
        <ClearFix />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route
            path="/product/add"
            element={
              <RequireAuth>
                <ProductAddEdit />
              </RequireAuth>
            }
          />
          <Route
            path="/product/edit/:id"
            element={
              <RequireAuth>
                <ProductAddEdit />
              </RequireAuth>
            }
          />
          <Route path="/portfolio">
            <Route index element={<Portfolio />} />
            {portfolioData.map((p, index) => (
              <Route
                key={index}
                path={`${p.link}`}
                element={<PortfolioItem {...p} />}
              />
            ))}
          </Route>
          {services.map((s, index) => (
            <Route
              key={index}
              path={`/${s.link}`}
              element={<Services {...s} />}
            />
          ))}
        </Routes>
        <Footer />
        <ScrollButton />
      </div>
    </div>
  );
}

export default App;
