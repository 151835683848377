export const validateInputs = (key, value) => {
  if (typeof value === "boolean") {
    return {};
  }
  if (value === null || value === undefined || value === '') {
    return { [key]: `${key} is required.` };
  }
  switch (key) {
    case "Name":
      // if (!`${value}`.match(/^[\\p{L} .'-]+$/)) {
      //   return { [key]: "Name is invalid." };
      // }
      break;
    case "Price":
      if (!`${value}`.match(/^\d{0,8}(\.\d{1,4})?$/i)) {
        return { [key]: "Price is invalid." };
      }
      break;
    case "SquareFt":
      if (!`${value}`.match(/^\d*\.?\d*$/i)) {
        return { [key]: "SquareFt is invalid." };
      }
      break;
    default:
      break;
  }

  return {};
};
