import { filter } from "lodash";
import { useSpinnerData } from "../../SpinnerProvider";
import { deleteFileFromItem } from "../../api";
import { getFileName } from "../../utils";

export const useFileDelete = (id, onSuccess) => {
  const { show, hide } = useSpinnerData();
  const deleteFile = (file) => {
    show();
    debugger;
    let fileName = getFileName(file);
    deleteFileFromItem(id, fileName).then(async (response) => {
        console.log(response);
        const data = await response.json();
        console.log(data);
        hide();
        if (response.status === 200) {
          onSuccess((f) => filter(f, (x) => x !== file));
        }
      })
      .catch((error) => {
        hide();
      });
  };
  return { deleteFile };
};
