import Link from "../components/Link";
import AppLogo from "./AppLogo";
import AppName from "./AppName";
import { navigationData } from "../constants";
import NavLinkItem, { useOutsideAlerter } from "./NavLinkItem";
import classNames from "classnames";
import { useLayoutEffect, useRef, useState } from "react";
import { useAuthenticatorData } from "../AuthenticatorProvider";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [openNavMenus, setOpenNavMenus] = useState(false);
  const [matches, setMatches] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setOpenNavMenus(false));
  const { user } = useAuthenticatorData();
  const location = useLocation();

  useLayoutEffect(() => {
    function updateMatches() {
      setMatches(window.matchMedia("(min-width: 992px)").matches);
    }
    window.addEventListener("resize", updateMatches);
    updateMatches();
    return () => window.removeEventListener("resize", updateMatches);
  }, []);

  return (
    <div className="header header-light">
      <div className="container">
        <nav
          id="navigation"
          ref={wrapperRef}
          className={classNames("navigation", {
            "navigation-portrait": !matches,
            "navigation-landscape": matches,
          })}
        >
          <div className="nav-header">
            <Link className="nav-brand fixed-logo" href="/">
              <div className="d-flex justify-content-start justify-content-md-center align-items-center">
                <AppLogo />
                <AppName />
              </div>
            </Link>
            <div
              className="nav-toggle"
              onClick={() => setOpenNavMenus("true")}
            ></div>
          </div>
          <div
            className={classNames("nav-menus-wrapper", {
              "nav-menus-wrapper-open": openNavMenus,
            })}
            style={{
              transitionProperty: "left",
              display: "flex",
              ...(user && location.pathname === "/products"
                ? { justifyContent: "space-between" }
                : {}),
            }}
          >
            {openNavMenus && (
              <span
                className="nav-menus-wrapper-close-button"
                onClick={() => setOpenNavMenus(false)}
              >
                ✕
              </span>
            )}
            <ul className="nav-menu">
              {navigationData.map((nd, index) => (
                <NavLinkItem
                  {...nd}
                  key={index}
                  onClick={() => setOpenNavMenus(false)}
                />
              ))}
            </ul>
            {user && location.pathname === "/products" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "0 10px",
                  ...(matches ? {} : { flex: 1 }),
                }}
              >
                <div
                  className="add-listing"
                  style={{
                    borderRadius: 5,
                    top: 16,
                    position: "relative",
                    height: 50,
                    padding: 10,
                    width: "100%",
                    background: "#27ae60",
                    display: "inline-flex",
                    marginBottom: 30,
                    alignContent: "space-around",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    href="/product/add"
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={() => setOpenNavMenus(false)}
                  >
                    <i className="fas fa-plus-circle mr-1"></i>Add Product
                  </Link>
                </div>
              </div>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
