import Link from "../../components/Link";
import { COMPANY_FULL_NAME_WITHOUT_LLC } from "../../constants";
import Testimonials from "../Home/Testimonials";

const testimonialData = [
  {
    testimonial: (
      <>
        {
          "We are so excited to be building and appreciate everything you did to get us to this point."
        }
        <br />
        {
          "We highly recommend Almodovar Drafting and Design to design your dream home.  Bryan went above and beyond in guiding us through the design process. He offered helpful and cost saving suggestions. He thoroughly listened to our wish. We felt confident in his ability to provide us with a finished product that would suit our needs/wants. We are very pleased with our plans that were delivered timely. We cannot say enough about Bryan’s professionalism, knowledge, efficiency, politeness and attention to detail."
        }
      </>
    ),
    testimonialBy: "Randy and Sheila Merritt",
    testimonialTitle: "Home Owners",
  },
  {
    testimonial:
      "Bryan and his team have been very detailed in their approach throughout the design process and are very involved with assisting with plan approvals and assisting with project scoping.  We are very pleased with the results we have seen",
    testimonialBy: "Mike and Aimee Rose",
    testimonialTitle: "Home Owners",
  },
  {
    testimonial:
      "You were fantastic! We will be happy to support you. Timely and very professional are words that I would describe you. Thanks!",
    testimonialBy: "Art and Elizabeth Smith",
    testimonialTitle: "Home Owners",
  },
  {
    testimonial: (
      <>
        {
          "Bryan took our rough drawings and made a beautiful home with plans we can replicate on different lots to build on. "
        }

        <br />
        {
          "He was thorough and delivered on time. he also was very responsive to helping us find trades to get the job done. "
        }
        <br />
        {"Highly recommend."}
      </>
    ),
    testimonialBy: "Melissa Haworth of Shorefronts Investments",
  },
  {
    testimonial:
      "Almodovar Drafting and Design's process to create a custom design for our home that met all of our needs and desires was most impressive. Working with Bryan Almodovar directly, we were able to communicate our ideas and see them quickly come to life. His service is beyond professional, knowledgeable and responsive. With each interaction, this company strives to listen to it's client's, maintains respect for their ideas and budget and strives to ultimately deliver a product of total satisfaction from start to finish. We could not be happier with the outcome.",
    testimonialBy: "Ray and Larraine White",
    testimonialTitle: "Home Owners",
  },
  {
    testimonial:
      "Bryan is one of the most gifted drafting and designers whom I was privileged to find. I had four previous designers that could not design a home to my liking. Bryan not only drew the home I described, but came up with fantastic ideas to utilize every space within my home. Bryan takes the time to get to know his clients, and the home that meets their needs and desires. I highly recommend him if you are looking for someone highly skilled at their craft, who will listen, and work with you, making your dream home a reality.",
    testimonialBy: "Sandy Cambria",
    testimonialTitle: "Home Owners",
  },
  {
    testimonial:
      "I am so impressed with the outcome of the plans That I am extremely impressed with the quality plans and professionalism of Almodovar Drafting & Design did for my Panama City Beach property.",
    testimonialBy: "Teresa Light",
    testimonialTitle: "Home Owners",
  },
];

const AboutUs = () => (
  <>
    <div
      className="page-title"
      style={{ background: "#f4f4f4 url(/assets/img/commercial-plans/2.jpg)" }}
      data-overlay="5"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="breadcrumbs-wrap">
              <h2 className="breadcrumb-title">{"About Us"}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="gray">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
            <div className="story-wrap explore-content">
              <h2>{COMPANY_FULL_NAME_WITHOUT_LLC}</h2>
              <span className="theme-cl">
                {"Check out our company story and work process"}
              </span>
              <p className="mt-4">
                {
                  "Our mission at Almodovar Drafting and Design is simple: to provide high-quality services for our valued clients. Our team goes above and beyond to cater to each project’s specific needs. Through open communication and exceptional service, we hope you’ll find what you’re looking for with our Drafting Service. Since our founding, we’ve worked with numerous clients throughout the area. Great service begins and ends with experienced and friendly professionals, which is why we put so much effort in our work."
                }
              </p>
              <p className="mt-4">
                {
                  "We complete projects efficiently and on schedule, and go above and beyond to form lasting relationships with our clients. Throughout the years, I’ve put in the necessary hours to develop and maintain an impressive design portfolio that demonstrates my talents and interests (and yes, that might mean creating blueprints well past midnight and forgoing a healthy home-cooked meal for something on the go)."
                }
              </p>
              <p className="mb-3">
                {
                  "For more information or general inquiries, get in touch today."
                }
              </p>
              <Link
                href="/contact-us"
                label="Contact Us"
                className="btn theme-bg btn-rounded"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <img src="/assets/img/bg.jpg" className="img-fluid rounded" alt="" />
          </div>
        </div>
      </div>
    </section>
    <Testimonials testimonialData={testimonialData} hideMoreTestimonialsBtn />
  </>
);

export default AboutUs;
