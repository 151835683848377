import {
  COMPANY_EMAIL,
  FORMATTED_COMPANY_CONTACT,
  linkBarData,
} from "../../constants";
import * as yup from "yup";
import { useCallback, useState } from "react";
import update from "immutability-helper";
import { useAlert } from "react-alert";
import SocialLink from "../../Header/SocialLink";
import { sendEmail } from "../../api";
import Spinner from "../../components/Spinner"

const ErrorMessage = ({ message }) => (
  <small className="help-block">
    <span style={{ color: "red" }}>{message}</span>
  </small>
);

const formSchema = yup.object().shape({
  name: yup.string().required(),
  phoneNumber: yup.string().required(),
  email: yup.string().email().required(),
  comment: yup.string().required(),
});

const ContactUs = () => {
  const alert = useAlert();
  const [showSubmit, setShowSubmit] = useState(true);
  const [values, setValues] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    comment: "",
  });
  // Create state for form errors:
  const [errors, setErrors] = useState({
    name: false,
    phoneNumber: false,
    email: false,
    comment: false,
  });

  const validateForm = useCallback(async () => {
    // Check the schema if form is valid:
    const isFormValid = await formSchema.isValid(values, {
      abortEarly: false, // Prevent aborting validation after first error
    });
    if (isFormValid) {
      setErrors({
        name: false,
        phoneNumber: false,
        email: false,
        comment: false,
      });
      return true;
    } else {
      // If form is not valid, check which fields are incorrect:
      formSchema.validate(values, { abortEarly: false }).catch((err) => {
        // Collect all errors in { fieldName: boolean } format:
        const errors = err.inner.reduce((acc, error) => {
          return {
            ...acc,
            [error.path]: true,
          };
        }, {});

        // Update form errors state:
        setErrors((prevErrors) =>
          update(prevErrors, {
            $set: errors,
          })
        );
      });
    }
    return false;
  }, [values]);

  const onFieldChange = useCallback((fieldName, value) => {
    setValues((prevValues) =>
      update(prevValues, {
        [fieldName]: {
          $set: value,
        },
      })
    );
  }, []);

  const onSubmit = useCallback(
    async (event) => {
      // Prevent form from submitting:
      event.preventDefault();
      setShowSubmit(false);
      if (await validateForm()) {
        // If form is valid, continue submission.

        sendEmail(values)
          .then(async (response) => {
            if (response.status === 200) {
              alert.success("Thanks for Submitting!");
            } else {
              alert.error("There was an error sending your email");
            }
            setValues({
              name: "",
              phoneNumber: "",
              email: "",
              comment: "",
            });
            setShowSubmit(true);
          });
      }
    },
    [alert, setValues, validateForm, values]
  );

  return (
    <>
      <div
        className="page-title"
        style={{ background: "#f4f4f4 url(/assets/img/outdoor-pools-landscape/1.jpg)" }}
        data-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="breadcrumbs-wrap">
                <h2 className="breadcrumb-title">{"Contact Us"}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="gray-simple">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-7">
              <div className="property_block_wrap">
                <form onSubmit={onSubmit} noValidate>
                  <div className="contact-form">
                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="name"
                      >
                        Name*:
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter Name"
                          name="name"
                          value={values.name}
                          onChange={(event) =>
                            onFieldChange("name", event.target.value)
                          }
                          onBlur={validateForm}
                        />
                        {errors.name && (
                          <ErrorMessage message="Please enter your name" />
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="phoneNumber"
                      >
                        Phone Number*:
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          id="phoneNumber"
                          placeholder="Enter Phone Number"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={(event) =>
                            onFieldChange("phoneNumber", event.target.value)
                          }
                          onBlur={validateForm}
                        />
                        {errors.phoneNumber && (
                          <ErrorMessage message="Please enter your phone number" />
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-sm-4" htmlFor="email">
                        Email*:
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter email"
                          name="email"
                          value={values.email}
                          onChange={(event) =>
                            onFieldChange("email", event.target.value)
                          }
                          onBlur={validateForm}
                        />
                        {errors.email && (
                          <ErrorMessage message="Please enter your email address" />
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="comment"
                      >
                        Comment*:
                      </label>
                      <div className="col-sm-10">
                        <textarea
                          className="form-control"
                          rows="5"
                          name="comment"
                          id="comment"
                          placeholder="Type your message here"
                          value={values.comment}
                          onChange={(event) =>
                            onFieldChange("comment", event.target.value)
                          }
                          onBlur={validateForm}
                        ></textarea>
                        {errors.comment && (
                          <ErrorMessage message="Please enter your message" />
                        )}
                      </div>
                    </div>
                    <div className="justify-content-center row">
                      <div className="form-group">
                        <div className="col-sm-offset-2 col-sm-10">
                          {showSubmit && (<button
                            type="submit"
                            className="btn theme-bg text-light rounded"
                          >
                            Submit
                          </button>)}
                          {!showSubmit && (<Spinner />)}
                        </div>
                      </div>
                    </div>
                    <div
                      id="message"
                      className="alert alert-success alert-dismissible fade"
                    ></div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4 col-md-5">
              <div className="contact-box">
                <div>
                  <i className="ti-user theme-cl"></i>
                  <h4>{"Contact Us"}</h4>
                  <p>{"Panama City Beach, FL 32413"}</p>
                  <p>{COMPANY_EMAIL}</p>
                  <p>Tel: {FORMATTED_COMPANY_CONTACT}</p>
                </div>
              </div>
              <ul
                className="top-social"
                style={{
                  background: "#232c3d",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "0",
                  borderRadius: "10px",
                  overflow: "hidden",
                  padding: "1em",
                }}
              >
                {linkBarData.map((lbd, index) => (
                  <li key={index}>
                    <SocialLink {...lbd} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
