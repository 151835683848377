import React from "react";
import ClearFix from "../../components/ClearFix";
import {
  COMPANY_FULL_NAME_WITHOUT_LLC,
  COMPANY_NAME_WITHOUT_LLC,
} from "../../constants";

const subHeading = [
  "Are you someone who wants to make their dream house a reality?",
  "Are you thinking of opening up your shop, but need some help with the layout?",
  "Are you a Developer, General Contractor, or Engineer looking for Drafting Services?",
];

const MainSection = () => (
  <div
    className="image-cover hero_banner"
    style={{
      background: "url(/assets/img/banner-1.png) no-repeat",
    }}
    data-overlay="0"
  >
    <div className="container">
      <h2 className="font-normal text-center mb-1">
        {"Welcome to"}
        <br />
        <strong>{COMPANY_NAME_WITHOUT_LLC}</strong>
      </h2>
      <div
        className="mb-4 text-mlixer text-light"
        style={{ textAlign: "left", float: "left" }}
      >
        {subHeading.map((sh, index) => (
          <div key={index}>
            {sh}
            <br />
          </div>
        ))}
      </div>
      <ClearFix />
      <div className="mb-4 text-mlixer text-light" style={{ float: "right" }}>
        <div>
          {`At ${COMPANY_FULL_NAME_WITHOUT_LLC}, we provide you with `}
          <span style={{ fontWeight: "bold" }}>{"TOP QUALITY"}</span>
          {
            " Drafting Services. We do any kind of drawings from pool drawings for permitting to a very detailed construction drawings for Shipyards, Residential and Commercials."
          }
        </div>
      </div>
      <ClearFix />
    </div>
  </div>
);

export default MainSection;
