import { pick } from "lodash";
import { useRef } from "react";
import { useSpinnerData } from "../../SpinnerProvider";
import { BasicFieldsWithId, ItemDetailsFields, formValidation, BasicFields } from "./index";
import { fetchProductById } from "../../api";

export const useFetchProduct = (onSuccess, onFileFetch) => {
  const { show, hide } = useSpinnerData();
  const fetchCountRef = useRef();

  const fetchProduct = (id) => {
    if (!fetchCountRef.current) {
      show();
      fetchCountRef.current = true;
      fetchProductById(id).then(async (response) => {
        fetchCountRef.current = false;
        const data = await response.json();
        if (response.status === 200 && data) {
          if (data.ImageUrls) {
            onFileFetch(data.ImageUrls.map(function(image){ return {Name: image.Name, Url: image.Url} }));
          }
          onSuccess({
            ...pick(data, [...BasicFieldsWithId, ...ItemDetailsFields]),
            errors: formValidation(
              pick(data, [...BasicFields, ...ItemDetailsFields])
            ),
          });
        }
        hide();
      });
    }
  };

  return { fetchProduct };
};
