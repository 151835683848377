export const serializeQueryObject = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  export const getFileName = (fullPath) => {
    return fullPath.split('\\').pop().split('/').pop();
  }