import { useState } from "react";
import { useMemo } from "react";
import { createContext, useContext } from "react";
import { login, register, logout } from "./api";

const AuthenticatorContext = createContext({ user: null, setUser: () => {} });

export const useAuthenticatorData = () => useContext(AuthenticatorContext);

export const useUserAuthentication = () => {
  return { login, register, logout };
};

const useFetchMembersData = () => {
  const localUserData = JSON.parse(sessionStorage.getItem("user") || "{}");
  const [user, setUser] = useState(localUserData.userName || null);

  return useMemo(
    () => ({
      user,
      setUser: (user) => setUser(user),
    }),
    [setUser, user]
  );
};

const AuthenticatorProvider = ({ children }) => {
  const data = useFetchMembersData();
  return (
    <AuthenticatorContext.Provider value={data}>
      {children}
    </AuthenticatorContext.Provider>
  );
};

export default AuthenticatorProvider;
