import { useState } from "react";
import Modal from "react-modal";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  useAuthenticatorData,
  useUserAuthentication,
} from "../AuthenticatorProvider";
import { useSpinnerData } from "../SpinnerProvider";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "9999",
    maxWidth: "850px",
    width: "100%",
  },
  overlay: {
    zIndex: "99999",
  },
};

const validateInputs = (key, value, form) => {
  const errorObj = {};
  switch (key) {
    case "userName":
      if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        errorObj[`${key}Error`] = "";
      } else {
        errorObj[`${key}Error`] = "User Name is invalid.";
      }
      break;
    case "password":
      if (
        value.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i
        )
      ) {
        errorObj[`${key}Error`] = "";
      } else {
        errorObj[`${key}Error`] = "Password is not matching defined criteria.";
      }
      break;
    case "confirmPassword":
      if (!form.password || form.password === value) {
        errorObj[`${key}Error`] = "";
      } else {
        errorObj[`${key}Error`] =
          "Password and Confirm Password is not matched.";
      }
      break;
    case "confirm":
      if (value) {
        errorObj[`errorMessage`] = "";
      } else {
        errorObj[`errorMessage`] = "Please accept terms and conditions.";
      }
      break;
    default:
      break;
  }

  return errorObj;
};

const defaultState = {
  userName: "",
  password: "",
  userNameError: null,
  passwordError: null,
  confirmPassword: "",
  confirmPasswordError: null,
  confirm: false,
  errorMessage: "",
};

const LoginSection = ({ onClose }) => {
  const [form, setForm] = useState(defaultState);
  const handleSetForm = (key, value) => {
    setForm((f) => ({
      ...f,
      ...validateInputs(key, value, f),
      [key]: value,
    }));
  };

  const { login } = useUserAuthentication();
  const { setUser } = useAuthenticatorData();
  const { visible, show, hide } = useSpinnerData();

  const handleLogin = () => {
    show();
    login(form.userName, form.password)
      .then(async (response) => {
        hide();
        const data = await response.json();
        if (response.status === 200) {
          sessionStorage.setItem(
            "ACCESS_TOKEN",
            `${data.token_type} ${data.access_token}`
          );
          delete data.access_token;
          delete data.token_type;
          sessionStorage.setItem("user", JSON.stringify(data));
          setUser(data.userName);
          onClose();
        } else {
          handleSetForm("errorMessage", data.error_description);
        }
      })
      .catch((error) => {
        hide();
        handleSetForm("errorMessage", error.message);
      });
  };

  const handleKeyPress = (key) => {
    if (key === "Enter") {
      handleLogin();
    }
  }

  const hasValidInputs = form.userNameError === "" && form.passwordError === "";

  return (
    <div className="login-form was-validated">
      <div className={`form-group ${form.userNameError ? "is-invalid" : ""}`}>
        <label>{"User Name"}</label>
        <div className="input-with-icon">
          <input
            type="text"
            className="form-control"
            value={form.userName}
            onChange={(e) => handleSetForm("userName", e.target.value)}
            onKeyPress={(e) => handleKeyPress(e.key)}
          />
          <i className="ti-user"></i>
        </div>
        <div className="invalid-feedback">{form.userNameError}</div>
      </div>

      <div className={`form-group ${form.passwordError ? "is-invalid" : ""}`}>
        <label>{"Password"}</label>
        <div className="input-with-icon">
          <input
            type="password"
            className="form-control"
            value={form.password}
            onChange={(e) => handleSetForm("password", e.target.value)}
            onKeyPress={(e) => handleKeyPress(e.key)}
          />
          <i className="ti-unlock"></i>
        </div>
        <div className="invalid-feedback">{form.passwordError}</div>
      </div>
      <div className={`form-group ${form.errorMessage ? "is-invalid" : ""}`}>
        <div className="invalid-feedback">{form.errorMessage}</div>
        <button
          disabled={visible || !hasValidInputs}
          onClick={handleLogin}
          className="btn btn-md full-width pop-login"
        >
          {"Login"}
        </button>
      </div>
    </div>
  );
};

const RegisterSection = ({ onClose }) => {
  const { register } = useUserAuthentication();

  const [form, setForm] = useState(defaultState);
  const { visible, show, hide } = useSpinnerData();
  const handleSetForm = (key, value) => {
    setForm((f) => ({
      ...f,
      ...validateInputs(key, value, f),
      [key]: value,
    }));
  };

  const handleRegister = () => {
    show();
    register(form.userName, form.password, form.confirmPassword)
      .then(async (response) => {
        hide();
        if (response.status === 200) {
          onClose();
        } else {
          const data = await response.json();
          handleSetForm("errorMessage", data.Message);
        }
      })
      .catch((error) => {
        hide();
        handleSetForm("errorMessage", error.message);
      });
  };

  const hasValidInputs =
    form.userNameError === "" &&
    form.passwordError === "" &&
    form.confirmPasswordError === "" &&
    form.confirm;

  return (
    <div className="login-form">
      <div className={`form-group ${form.userNameError ? "is-invalid" : ""}`}>
        <label>{"Email ID"}</label>
        <div className="input-with-icon">
          <input
            type="email"
            className="form-control"
            value={form.userName}
            onChange={(e) => handleSetForm("userName", e.target.value)}
          />
          <i className="ti-user"></i>
        </div>
        <div className="invalid-feedback">{form.userNameError}</div>
      </div>

      <div className={`form-group ${form.passwordError ? "is-invalid" : ""}`}>
        <label>{"Password"}</label>
        <div className="input-with-icon">
          <input
            type="password"
            className="form-control"
            value={form.password}
            onChange={(e) => handleSetForm("password", e.target.value)}
          />
          <i className="ti-unlock"></i>
        </div>
        <div className="invalid-feedback">{form.passwordError}</div>
      </div>

      <div
        className={`form-group ${form.confirmPasswordError ? "is-invalid" : ""
          }`}
      >
        <label>{"Confirm Password"}</label>
        <div className="input-with-icon">
          <input
            type="password"
            className="form-control"
            value={form.confirmPassword}
            onChange={(e) => handleSetForm("confirmPassword", e.target.value)}
          />
          <i className="ti-unlock"></i>
        </div>
        <div className="invalid-feedback">{form.confirmPasswordError}</div>
      </div>

      <div className={`form-group ${form.errorMessage ? "is-invalid" : ""}`}>
        <div className="eltio_ol9">
          <div className="eltio_k1">
            <input
              id="dds"
              className="checkbox-custom"
              name="dds"
              type="checkbox"
              checked={form.confirm}
              onChange={(e) => handleSetForm("confirm", e.target.checked)}
            />
            <label htmlFor="dds" className="checkbox-custom-label">
              {"By using the website, you accept the terms and conditions"}
            </label>
          </div>
        </div>
        <div className="invalid-feedback">{form.errorMessage}</div>
      </div>

      <div className={`form-group`}>
        <button
          disabled={visible || !hasValidInputs}
          onClick={handleRegister}
          className="btn btn-md full-width pop-login"
        >
          {"Register"}
        </button>
      </div>
    </div>
  );
};

const SignInModal = ({ show, onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Modal isOpen={show} style={customStyles} contentLabel="Sign In">
      <div className="resp_log_wrap">
        <div
          className="resp_log_thumb"
          style={{ background: "url(assets/img/log.jpg)no-repeat" }}
        ></div>
        <Tabs
          className="resp_log_caption"
          selectedIndex={tabIndex}
          onSelect={(index) => {
            setTabIndex(index);
          }}
        >
          <span className="mod-close" onClick={onClose}>
            <i className="ti-close"></i>
          </span>
          <TabList
            className="nav nav-pills tabs_system center"
            id="pills-tab"
            role="tablist"
          >
            <Tab className="nav-item" style={{ marginRight: 10 }}>
              <i className="fas fa-sign-in-alt mr-2"></i>
              {"Login"}
            </Tab>
            <Tab className="nav-item">
              <i className="fas fa-user-plus mr-2"></i>
              {"Register"}
            </Tab>
          </TabList>
          <TabPanel
            className="tab-pane fade show"
            id="pills-login"
            role="tabpanel"
          >
            <LoginSection onClose={onClose} />
          </TabPanel>
          <TabPanel
            className="tab-pane fade show"
            id="pills-signup"
            role="tabpanel"
          >
            <RegisterSection onClose={() => setTabIndex(0)} />
          </TabPanel>
        </Tabs>
      </div>
    </Modal>
  );
};

export default SignInModal;
