function getBaseUrl() {
  let hostName = window.location.hostname;
  if (hostName.indexOf('dev.almodovardd') > -1) {
    return 'https://dev.almodovardd.com/api';
  } else if (hostName.indexOf("addnb.net") > -1) {
    return 'https://addnb.net/api'
  } else {
    return 'http://localhost:19921/api/'
  }
}

export const fetchEnums = () =>
  fetch(`${getBaseUrl()}/enums`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

export const fetchProductById = (id) =>
  fetch(`${getBaseUrl()}/products/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
  });

export const search = (searchBody) =>
  fetch(`${getBaseUrl()}/products/search`, {
    method: "POST",
    body: JSON.stringify(searchBody),
    headers: {
      "Content-Type": "application/json",
    },
  });

export const saveProduct = (form, BasicFieldsWithId, ItemDetailsFields, pick) =>
  fetch(`${getBaseUrl()}/products/save`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("ACCESS_TOKEN"),
    },
    body: JSON.stringify({
      ...pick(form, BasicFieldsWithId),
      ItemDetails: [pick(form, ItemDetailsFields)],
    }),
  });

export const deleteProduct = (id) =>
  fetch(`${getBaseUrl()}/api/products/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("ACCESS_TOKEN"),
    },
  });

export const deleteFileFromItem = (id, file) =>
  fetch(
    `${getBaseUrl()}/Photos/Delete?itemId=${id}&fileName=${file}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("ACCESS_TOKEN"),
      },
    }
  );

export const uploadFileForItem = (itemId, formdata) =>
  fetch(
    `${getBaseUrl()}/Photos/Upload?itemId=${itemId}`,
    {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem("ACCESS_TOKEN"),
      },
      body: formdata,
    }
  );

export const login = (username, password) =>
  fetch(`${getBaseUrl()}/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: (() => {
      var urlencoded = new URLSearchParams();
      urlencoded.append("username", username);
      urlencoded.append("password", password);
      urlencoded.append("grant_type", "password");
      return urlencoded;
    })(),
  });

export const logout = () =>
  fetch(`${getBaseUrl()}/Account/Logout`, {
    method: "POST",
  });

export const register = (username, password, confirmPassword) =>
  fetch(`${getBaseUrl()}/account/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Email: username,
      Password: password,
      ConfirmPassword: confirmPassword,
    }),
  });

export const sendEmail = (contactForm) =>
  fetch(`${getBaseUrl()}/contact/sendEmail`, {
    method: "POST",
    body: JSON.stringify(contactForm),
    headers: {
      "Content-Type": "application/json",
    },
  });