import { Link } from "react-router-dom";
import { useAuthenticatorData } from "../../AuthenticatorProvider";

export const Price = ({ price, id }) => {
  const { user } = useAuthenticatorData();

  return (
    <div className="listing-detail-footer">
      <div className="footer-first">
        <div className="foot-location">
          <h6 className="listing-card-info-price mb-0 p-0">${price}</h6>
        </div>
      </div>
      <div className="footer-flex">
        <ul className="selio_style">
          {user && (
            <li>
              <div className="prt_saveed_12lk">
                <Link to={`/product/edit/${id}`}>
                  <i className="ti-pencil"></i>
                </Link>
              </div>
            </li>
          )}
          <li>
            <div className="prt_saveed_12lk">
              <Link to={`/product/${id}`}>
                <i className="ti-arrow-right"></i>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
