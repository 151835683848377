export const Features = ({ bed, bath, size }) => (
  <div className="price-features-wrapper">
    <div className="list-fx-features">
      <div className="listing-card-info-icon">
        <div className="inc-fleat-icon">
          <img src="assets/img/bed.svg" width="13" alt="" />
        </div>
        {bed} Beds
      </div>
      <div className="listing-card-info-icon">
        <div className="inc-fleat-icon">
          <img src="assets/img/bathtub.svg" width="13" alt="" />
        </div>
        {bath} Bath
      </div>
      <div className="listing-card-info-icon">
        <div className="inc-fleat-icon">
          <img src="assets/img/move.svg" width="13" alt="" />
        </div>
        {size} sqft
      </div>
    </div>
  </div>
);
