export const CustomText = ({
  className,
  form,
  fieldKey,
  label,
  onChange,
}) => (
  <div
    className={`form-group ${className} ${
      form.errors[fieldKey] ? "is-invalid" : ""
    }`}
  >
    <label>
      {label}
      <span className="required">*</span>
    </label>
    <input
      type="text"
      value={form[fieldKey]}
      onChange={(e) => onChange(fieldKey, e.target.value)}
      className="form-control"
    />
    <div className="invalid-feedback">{form.errors[fieldKey]}</div>
  </div>
);
