import { useAuthenticatorData } from "../../AuthenticatorProvider";

export const Details = ({ id, name, itemType, itemCategory, onDelete }) => {
  const { user } = useAuthenticatorData();
  return (
    <div className="listing-detail-wrapper">
      <div className="listing-short-detail-wrap">
        <div className="_card_list_flex mb-2">
          <div className="_card_flex_01" style={{ marginLeft: -5 }}>
            <span className="_list_blickes _netork">{itemType}</span>
            <span className="_list_blickes types">{itemCategory}</span>
          </div>
          <div className="_card_flex_last" style={{ display: "flex" }}>
            {user && (
              <div
                className="prt_saveed_12lk"
                style={{ marginRight: 10 }}
                onClick={onDelete}
              >
                <label
                  style={{
                    color: "#bec7d8",
                    cursor: "pointer",
                    fontSize: 20,
                    marginBottom: 0,
                    lineHeight: 1,
                  }}
                >
                  <i className="ti-trash"></i>
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="_card_list_flex">
          <div className="_card_flex_01">
            <h4 className="listing-name verified">{name}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};
