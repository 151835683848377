import { useState } from "react";
import { useMemo } from "react";
import { createContext, useContext } from "react";

const SpinnerContext = createContext({ searchData: {} });

export const useSpinnerData = () => useContext(SpinnerContext);

const useFetchSpinner = () => {
  const [visible, setVisible] = useState(false);

  return useMemo(
    () => ({
      visible,
      show: () => setVisible(true),
      hide: () => setVisible(false),
    }),
    [visible, setVisible]
  );
};

const SpinnerProvider = ({ children }) => {
  const data = useFetchSpinner();
  return (
    <SpinnerContext.Provider value={data}>{children}</SpinnerContext.Provider>
  );
};

export default SpinnerProvider;
