import Slider from "react-slick";
import { LightBoxGallery, GalleryItem } from "@sekmet/react-magnific-popup";
import magnificPopupConfig from "../../magnific-popup-config";
import parse from 'html-react-parser';
import { serializeQueryObject } from "../../utils";
import { Link } from "react-router-dom";
import { identity, pickBy } from "lodash";

const settings = {
  dots: true,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000
};

const Services = ({ name, link, description, photos, itemTypeId }) => (
  <section className="gray gallery_parts pt-2 pb-2 d-none d-sm-none d-md-none d-lg-none d-xl-block">
    <div className="container pb-5">
      <div className="row">
        <div className="col-md-6 col-sm-12 pr-1">
          <div className="property_block_wrap">
            <div className="property_block_wrap_header">
              <h4 className="property_block_title">{name}</h4>
            </div>
            <div className="block-body">
              <div>{parse(description)}</div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 pl-1 text-center">
          <div
            style={{
              border: "2px solid #f0f2f7",
              borderRadius: 6,
              padding: "1rem 1.5rem",
            }}
          >
            <LightBoxGallery
              className="popup-gallery"
              config={magnificPopupConfig}
            >
              <Slider
                {...settings}
                className="modern-testimonial"
                style={{ marginBottom: "2rem" }}
              >
                {photos.map((p, index) => (
                  <div className="featured_slick_padd" key={index}>
                    <GalleryItem
                      href={`/assets/img/${link}/${p}`}
                      className="mfp-gallery"
                    >
                      <img
                        src={`/assets/img/${link}/${p}`}
                        className="img-fluid mx-auto"
                        alt=""
                      />
                    </GalleryItem>
                  </div>
                ))}
              </Slider>
            </LightBoxGallery>
            <div style={{textAlign: "center"}}>
              <Link
                  to={`/products?${serializeQueryObject(
                    pickBy(
                      {
                        minArea: 0,
                        maxArea: 10000,
                        sortById: 1,
                        itemTypeId: itemTypeId,
                      },
                      identity
                    )
                  )}`}
                  className="btn search-btn"
                  style={{maxWidth: 300}}
                >
                  <span style={{paddingRight: 10}}>See Available Products</span>
                  <i className="fa fa-search"></i>
                </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Services;
