import { forEach } from "lodash";
import { useSpinnerData } from "../../SpinnerProvider";
import { uploadFileForItem } from "../../api";

export const useFileUpload = (itemId, onSuccess) => {
  const { show, hide } = useSpinnerData();
  const uploadFile = (newFiles) => {
    show();
    var formdata = new FormData();

    forEach(newFiles, (file) => {
      formdata.append("file", file, file.name);
    });

    uploadFileForItem(itemId, formdata)
      .then(async (response) => {
        console.log(response);
        const data = await response.json();
        console.log(data);
        hide();
        if (response.status === 200) {
          onSuccess((f) => [...f, {Url: data}]);
        }
      })
      .catch((error) => {
        hide();
      });
  };
  return { uploadFile };
};
