import React from "react";
import Link from "../components/Link";
import { navigationData } from "../constants";

const Layout = () => (
  <ul className="footer-menu">
    {navigationData.map((nd, index) => (
      <li key={index}>
        {nd.link && <Link href={nd.link} label={`${nd.name} Page`} />}
        {nd.subItems && (
          <ul className="footer-menu">
            {nd.subItems.map((nd, index) => (
              <li key={index}>
                {nd.link && <Link href={nd.link} label={`${nd.name} Page`} />}
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
);

export default Layout;
