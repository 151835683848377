import Link from "../../components/Link";

const Portfolios = ({ portfolioData }) => (
  <div className="container">
    <div className="row justify-content-center">
      {portfolioData.map((x, index) => (
        <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
          <Link href={x.link} className="img-wrap">
            {x.title && (
              <div className="location_wrap_content visible">
                <div className="location_wrap_content_first">
                  <h4>{x.title}</h4>
                </div>
                <div className="location_btn">
                  <i className="fa fa-arrow-right"></i>
                </div>
              </div>
            )}
            <div
              className="img-wrap-background"
              style={{ backgroundImage: `url(${x.mainImg})` }}
            ></div>
          </Link>
        </div>
      ))}
    </div>
  </div>
);

export default Portfolios;
