import PortfolioHeading from "./PortfolioHeading";
import Portfolios from "./Portfolios";

export const portfolioData = [
  {
    title: "Bayshore Plan",
    link: "bayshore-plan",
    mainImg: "/assets/img/portfolio/Bayshore%20Plan/main.png",
    portfolioData: [
      "/assets/img/portfolio/Bayshore%20Plan/1.png",
      "/assets/img/portfolio/Bayshore%20Plan/2.png",
      "/assets/img/portfolio/Bayshore%20Plan/3.jpg",
      "/assets/img/portfolio/Bayshore%20Plan/4.jpg",
      "/assets/img/portfolio/Bayshore%20Plan/5.png",
      "/assets/img/portfolio/Bayshore%20Plan/6.png",
      "/assets/img/portfolio/Bayshore%20Plan/7.png",
      "/assets/img/portfolio/Bayshore%20Plan/8.png",
      "/assets/img/portfolio/Bayshore%20Plan/9.png",
    ],
  },
  {
    title: "Chianti Plan",
    link: "chianti-plan",
    mainImg: "/assets/img/portfolio/Chianti%20Plan/main.webp",
    portfolioData: [
      "/assets/img/portfolio/Chianti%20Plan/2.webp",
      "/assets/img/portfolio/Chianti%20Plan/3.webp",
      "/assets/img/portfolio/Chianti%20Plan/4.webp",
    ],
  },
  {
    title: "Creek View Plan",
    link: "creek-view-plan",
    mainImg: "/assets/img/portfolio/Creek%20View%20Plan/main.webp",
    portfolioData: [
      "/assets/img/portfolio/Creek%20View%20Plan/1.webp",
      "/assets/img/portfolio/Creek%20View%20Plan/2.webp",
      "/assets/img/portfolio/Creek%20View%20Plan/3.webp",
      "/assets/img/portfolio/Creek%20View%20Plan/4.webp",
      "/assets/img/portfolio/Creek%20View%20Plan/5.jpeg",
      "/assets/img/portfolio/Creek%20View%20Plan/6.webp",
      "/assets/img/portfolio/Creek%20View%20Plan/7.webp",
      "/assets/img/portfolio/Creek%20View%20Plan/8.webp",
      "/assets/img/portfolio/Creek%20View%20Plan/9.png",
      "/assets/img/portfolio/Creek%20View%20Plan/10.webp",
      "/assets/img/portfolio/Creek%20View%20Plan/11.webp",
    ],
  },
  {
    title: "Dolphin Plan",
    link: "dolphin-plan",
    mainImg: "/assets/img/portfolio/Dolphin%20Plan/main.webp",
    portfolioData: [
      "/assets/img/portfolio/Dolphin%20Plan/1.webp",
      "/assets/img/portfolio/Dolphin%20Plan/2.webp",
      "/assets/img/portfolio/Dolphin%20Plan/3.webp",
      "/assets/img/portfolio/Dolphin%20Plan/4.webp",
    ],
  },
  {
    title: "Gulf Plan",
    link: "gulf-plan",
    mainImg: "/assets/img/portfolio/Gulf%20Plan/main.webp",
    portfolioData: [
      "/assets/img/portfolio/Gulf%20Plan/1.webp",
      "/assets/img/portfolio/Gulf%20Plan/2.webp",
      "/assets/img/portfolio/Gulf%20Plan/3.webp",
      "/assets/img/portfolio/Gulf%20Plan/4.webp",
      "/assets/img/portfolio/Gulf%20Plan/5.webp",
    ],
  },
  {
    title: "Lakeshore Plan",
    link: "lakeshore-plan",
    mainImg: "/assets/img/portfolio/Lakeshore%20Plan/main.webp",
    portfolioData: [
      "/assets/img/portfolio/Lakeshore%20Plan/1.webp",
      "/assets/img/portfolio/Lakeshore%20Plan/2.webp",
      "/assets/img/portfolio/Lakeshore%20Plan/3.webp",
      "/assets/img/portfolio/Lakeshore%20Plan/4.webp",
      "/assets/img/portfolio/Lakeshore%20Plan/5.webp",
      "/assets/img/portfolio/Lakeshore%20Plan/6.webp",
      "/assets/img/portfolio/Lakeshore%20Plan/7.webp",
      "/assets/img/portfolio/Lakeshore%20Plan/8.webp",
      "/assets/img/portfolio/Lakeshore%20Plan/9.webp",
      "/assets/img/portfolio/Lakeshore%20Plan/10.webp",
      "/assets/img/portfolio/Lakeshore%20Plan/11.webp",
    ],
  },
  {
    title: "Vita di Lusso Plan",
    link: "vita-di-lusso-plan",
    mainImg: "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/main.webp",
    portfolioData: [
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/1.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/2.png",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/3.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/4.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/5.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/6.jpg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/7.png",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/8.png",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/9.jpg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/10.png",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/11.jpg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/12.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/13.png",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/14.png",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/15.png",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/16.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/17.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/18.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/19.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/20.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/21.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/22.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/23.jpeg",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/24.png",
      "/assets/img/portfolio/Vita%20di%20Lusso%20Plan/25.jpeg",
    ],
  },
  {
    title: "Lucarne Plan",
    link: "lucarne-plan",
    mainImg: "/assets/img/portfolio/Lucarne%20Plan/main.webp",
    portfolioData: [
      "/assets/img/portfolio/Lucarne%20Plan/1.png",
      "/assets/img/portfolio/Lucarne%20Plan/2.png",
      "/assets/img/portfolio/Lucarne%20Plan/3.jpeg",
      "/assets/img/portfolio/Lucarne%20Plan/4.jpg",
      "/assets/img/portfolio/Lucarne%20Plan/5.jpg",
      "/assets/img/portfolio/Lucarne%20Plan/6.jpg",
      "/assets/img/portfolio/Lucarne%20Plan/7.jpg",
      "/assets/img/portfolio/Lucarne%20Plan/8.jpg",
      "/assets/img/portfolio/Lucarne%20Plan/9.jpg",
      "/assets/img/portfolio/Lucarne%20Plan/10.jpeg",
      "/assets/img/portfolio/Lucarne%20Plan/11.jpeg",
      "/assets/img/portfolio/Lucarne%20Plan/12.jpeg",
      "/assets/img/portfolio/Lucarne%20Plan/13.jpeg",
      "/assets/img/portfolio/Lucarne%20Plan/14.jpeg",
      "/assets/img/portfolio/Lucarne%20Plan/15.jpeg",
    ],
  },
  {
    title: "Magnolia Plan",
    link: "magnolia-plan",
    mainImg: "/assets/img/portfolio/Magnolia%20Plan/main.webp",
    portfolioData: [
      "/assets/img/portfolio/Magnolia%20Plan/1.png",
      "/assets/img/portfolio/Magnolia%20Plan/2.png",
      "/assets/img/portfolio/Magnolia%20Plan/3.png",
      "/assets/img/portfolio/Magnolia%20Plan/4.png",
      "/assets/img/portfolio/Magnolia%20Plan/5.jpeg",
      "/assets/img/portfolio/Magnolia%20Plan/6.png",
      "/assets/img/portfolio/Magnolia%20Plan/7.jpeg",
      "/assets/img/portfolio/Magnolia%20Plan/8.jpeg",
      "/assets/img/portfolio/Magnolia%20Plan/9.jpeg",
      "/assets/img/portfolio/Magnolia%20Plan/10.jpeg",
      "/assets/img/portfolio/Magnolia%20Plan/11.jpeg",
      "/assets/img/portfolio/Magnolia%20Plan/12.png",
    ],
  },
];

const Portfolio = () => (
  <section className="gray min">
    <div className="container">
      <PortfolioHeading />
      <Portfolios portfolioData={portfolioData} />
    </div>
  </section>
);

export default Portfolio;
