import { find } from "lodash";
import Select from "react-select";

export const CustomSelect = ({
  className,
  form,
  fieldKey,
  label,
  onChange,
  options,
  isBoolean,
}) => (
  <div
    className={`form-group ${className} ${
      form.errors[fieldKey] ? "is-invalid" : ""
    }`}
  >
    <label>
      {label}
      <span className="required">*</span>
    </label>
    <Select
      classNamePrefix="custom"
      styles={{
        menuPortal: (baseStyles, state) => ({
          ...baseStyles,
          zIndex: 99,
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          minHeight: 54,
          borderColor: "#e7eaf1",
        }),
        indicatorSeparator: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: "#e7eaf1",
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          color: "#707e9c",
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          color: "#707e9c",
        }),
      }}
      value={find(options, (x) =>
        x.value === isBoolean ? form[fieldKey] : `${form[fieldKey]}`
      )}
      onChange={(data) => onChange(fieldKey, data.value)}
      menuPosition="fixed"
      options={options}
      className="form-control p-0 border-0"
    />
    <div className="invalid-feedback">{form.errors[fieldKey]}</div>
  </div>
);
