import React from "react";
import Link from "../../components/Link";

const ServicesDescription = `We are a one stop shop here. Select what you want to do, we will design it and we can take care of building it or fabricate it for you. You want to present and idea with renderings too? We got you.`;

const ServicesSection = ({ servicesData }) => (
  <section className="gray min">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-7 col-md-8">
          <div className="sec-heading center">
            <h2>{"Services"}</h2>
            <p>{ServicesDescription}</p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        {servicesData.map((s, index) => (
          <div key={index} className="col-lg-3 col-md-4 col-sm-6">
            <div className="_category_box">
              <Link href={s.link}>
                <div className="_category_elio">
                  <div className="_category_thumb">
                    <img src={s.icon} className="img-fluid hover" alt="" />
                    <img
                      src={s.hoverIcon}
                      className="img-fluid simple"
                      alt=""
                    />
                  </div>
                  <div className="_category_caption">
                    <h5>{s.name}</h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default ServicesSection;
