import React from "react";

const Testimonial = ({ testimonial, testimonialBy, testimonialTitle }) => (
  <div className="single_items">
    <div className="_smart_testimons">
      <div className="facts-detail">
        <p>{testimonial}</p>
      </div>
      <div className="_smart_testimons_info">
        <h5>{testimonialBy}</h5>
        <div className="_ovr_posts">
          <span>{testimonialTitle}</span>
        </div>
      </div>
    </div>
  </div>
);

export default Testimonial;
